<template>
  <div>
    <div class="contents">
         <div class="mainbox">
        <el-tabs>
          <el-tab-pane label="团长设置" name="first"></el-tab-pane>
        </el-tabs>
        <el-button @click="editFun" type="primary" style="float:right; margin-bottom: 10px">去设置</el-button>


       <el-table
        :data="params"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="index"
          width="40" header-align="center" align="center">
        </el-table-column>
        
        <el-table-column
          prop="autoMatchSupplier"
          label="订单自动匹配供应商"
          header-align="center" align="center">
          <template slot-scope="scope">
            {{scope.row.autoMatchSupplier==1?'是':'否'}}
          </template>
        </el-table-column>

        <el-table-column
          prop="autoSendAfterMatchedSupplier"
          label="匹配完供应商立即交单"
          header-align="center" align="center">
          <template slot-scope="scope">
            {{scope.row.autoSendAfterMatchedSupplier==1?'是':'否'}}
          </template>
        </el-table-column>
         <el-table-column
          label="自动交单时间"
         header-align="center" align="center"
         >
          <template slot-scope="scope" >
            <div v-if="scope.row.autoSendSupplier && scope.row.autoSendMoments.length>0">
           <span class="itembox" v-for="(tag,index) in scope.row.autoSendMoments" :key="index">{{tag}}</span>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>      
        <el-table-column
          prop="autoSendSupplier"
          label="是否自动交单给供应商"
           header-align="center" align="center"
          >
          <template slot-scope="scope">
            {{scope.row.autoSendSupplier==1?'是':'否'}}
          </template>
        </el-table-column>
       
       <el-table-column
      fixed="right"
      label="操作"
      width="100"
      header-align="center" align="center">
      <template slot-scope="scope">
      
        <el-button @click="editFun(scope.row)" type="text" size="small">编辑</el-button>
       
      </template>
    </el-table-column>
      </el-table>
      
      </div>
    </div>

    
  </div>
</template>

<script>
import { getData } from "@/api/system";
import {
  MessageBox
} from 'element-ui'
export default {
  name: 'Channel',
  data() {
    return {
      params: [],
      total: 0,
      page: 1,
      pageSize: 20,
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
   getSetData(){
      var that = this
      var datas = {}
      getData(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          if(res.data && res.data.retailerSetting){
          that.params.push(res.data.retailerSetting)
          }
        }else{
          this.$message.error(res.message)
        }
      })
   },
   editFun(){
     this.$router.push({
        path: "/system/detail"
     })
   }
    
  },
  mounted(){
    this.getSetData()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.itembox{
  font-size: 12px;
  color: #999;
  cursor: pointer;
  border: 1px solid #eee;
  padding: 0 20px;
  display: inline-block;
  margin: 5px;
  background-color: #f3f3f3;
}

</style>